import React, { FC } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from '../Spinner/Spinner';
import { makeStyles } from '@material-ui/core/styles';
import Box from '../Box/Box';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor:'rgba(0, 0, 0, 0.04)',
  },
}));

const PageSpinner: FC<{ withOutBackDrop?: boolean, containerStyle?: React.CSSProperties }> = ({ withOutBackDrop, containerStyle }) => {
  const classes = useStyles();

  if (withOutBackDrop) {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' style={containerStyle}>
        <Spinner width="4rem" height="4rem" />
      </Box>
    )
  }
  
  return (
    <Backdrop open={true} className={classes.backdrop}>
      <Spinner width="4rem" height="4rem" />
    </Backdrop>
  )
};

export default PageSpinner;
